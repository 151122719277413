<template>
    <div>

      <div class="titleMap">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10804.5339708871!2d8.483733!3d47.3898282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900b6705aa11cf%3A0xb892ed504207f89a!2sRitual%20Ornaments%20Tattoo!5e0!3m2!1sde!2sch!4v1660775384939!5m2!1sde!2sch" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>      </div>

        <div class="contentContainerContact" >
          <span v-html="wpPages[0].content.rendered"></span>
            <span> <a  :href="config.facebook"><i class="fab fa-facebook-square"></i></a> <a  :href="config.instagram"><i class="fab fa-instagram"></i></a> </span>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'ContactView',
    components: {
      
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      config:{required:true},
      wpPages:{required: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>