<template>
  <div class="home">
    
       <!--  <div class="titleImg"></div> -->
    <!--</var> <button class="btnHome mobileVisible"  @click="tuneNavigation">Menu</button> -->
       <div class="video-container">
          <video  autoplay="autoplay" loop id="index-video" muted playsInline autobuffer  class="desktopVisible"  preload="none">
            <source src="../assets/RawCutShort_desktop.mp4" type="video/mp4" >
          </video>  
          <video  autoplay="autoplay" loop id="index-video" muted playsInline autobuffer  class="mobileVisible"  preload="auto">
            <source src="../assets/RawCutShort_mobile.mp4" type="video/mp4" >
          </video>  
        </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    
  },
  props: {
    tuneNavigation:{type:Function},
  },
}
</script>


<style scoped>



.btnHome{
     background-color: #fff;
    border: medium none;
    color: #ccc;
    display: block;
    font-size: 18px;
    left: 0;
    margin: 0 auto;
    padding: 8px 16px;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    border-radius: 4px;
    font-size: 24px;
    opacity: .7;
    border: 1px solid #ccc;
 font-family: 'Cinzel';
  font-weight: bold;
 }
.video-container {
 position: absolute;
 top: 0;
 left: 0;
  width: 100vw;
  height: 100vh; 
  overflow: hidden !important;
  z-index: 1;
}

.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 
  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>
