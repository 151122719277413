<template>
    <div>
      <div class="titleImgPortrait">
        <div v-parallax="0.2">
          <h2  v-html="wpPages[1].title.rendered"></h2>
         
        </div>
      </div>

      <div class="contentContainerAbout">
        <div class="col" v-html="wpPages[1].content.rendered"></div>
        
        <div class="col">
           <h1> &nbsp; </h1>
           <div class="zahlen">
          <div class="countContainer">
            

            <div>
              <number
    ref="number1"
	:from="0"
	:to="4"
	:duration="3"
    :delay="0"
    easing="Power1.easeOut"/>
            </div>
            <h5>Guestspot Countries</h5> 
          </div>
          <div class="countContainer">
            <div>
              <number
    ref="number1"
	:from="0"
	:to="8"
	:duration="3"
    :delay="0"
    easing="Power1.easeOut"/>
            </div>
            <h5>Years of Experience</h5>
          </div>
          <div class="countContainer">
            <div>
              <number
    ref="number1"
	:from="0"
	:to="150"
	:duration="3"
    :delay="0"
    easing="Power1.easeOut"/>
            </div>
            <h5>Clients</h5>
          </div>
          <div class="countContainer">
            <div>
              <number
    ref="number1"
	:from="0"
	:to="100"
	:duration="3"
    :delay="0"
    easing="Power1.easeOut"/>%
            </div>
            <h5>Heart & Soul</h5>
          </div>
          </div>
        </div>
        
      </div>

    </div>
</template>
<script>



  import Vue from 'vue'
  import VueParallaxJs from 'vue-parallax-js'
  Vue.use(VueParallaxJs)
  import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)


  export default {
    name: 'AboutView',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      wpPages:{required: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>