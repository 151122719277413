<template>
  <div id="app">
 
    <transition enter-active-class="animated fadeIn animate__fast" leave-active-class="animated slideOutRight " mode="out-in" @after-enter="rocket=false;transition = false"  @before-leave="inTransition" @after-leave="rocket=true">
      <div class="transition-overlay" v-if="transition">
        <transition leave-active-class="animated slideOutUp  animate__fast">
          <img   alt="Vue logo" src="./assets/rocket.gif" v-if="rocket">
        </transition>
      </div>
    </transition>

    <transition enter-active-class="animated fadeIn animate__fast" leave-active-class="animated fadeOut animate__fast" mode="out-in" @enter="navigationOpen = true"  @before-leave="navigationOpen = false">
      <div v-if="navigation" class="navigation-overlay">
        <div class="mainMenu">
          <header>
             <div class="logoContainer">
            <img @click="mainNavigation('/')"  src="./assets/logo.png" />
          </div>
            <div @click="navigation = !navigation;navigationOpen = !navigationOpen"  class="navigationBtn"> <i class="fas fa-times"></i></div>

          </header>
       </div>
          <transition enter-active-class="animated slideInUp animate__fast" leave-active-class="animated slideOutDown animate__fast" mode="out-in">
       
            <nav v-if="navigationOpen"  >
              
                <ul>
                  <li v-for="(item, x) in menu" :key='x' ><a href="#" @click="mainNavigation(item.location)">{{item.title}}</a></li>
                </ul>
               <span> <a :href="config.facebook"><i class="fab fa-facebook-square"></i></a> <a  :href="config.instagram"><i class="fab fa-instagram"></i></a> </span>
            </nav>
           
        
          </transition>
        </div>
    </transition>
    <div class="mainMenu">
      <header>
        
          <div class="logoContainer">
            <img @click="mainNavigation('/')"  src="./assets/logo.png" />
          </div>
          <div  @click="navigation= !navigation" class="navigationBtn">  <i class="fas fa-bars"></i></div>
      </header>
    </div>
    <div class="wrapper">
      <router-view :tuneNavigation.sync="tuneNavigation" :config="config" :wpPosts="wpPosts" :wpPages="wpPages" />
    </div>
     
      <a :href="'https://wa.me/'+config.phoneShort" class="icnWhatsapp mobileVisible"><i class="fab fa-whatsapp"></i></a>
    <!-- <div class="footer">
        © J. Nocera 2022
        <a href="#"><i class="fab fa-facebook-square"></i></a> <a href="#"><i class="fab fa-linkedin"></i></a> 

     
    </div>-->
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

  export default {
    name: 'App',
    components: {
      //axios,
      //VueAxios
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        wpPages:[],
        wpPosts:[],
        config:{
          phone:'+41 79 938 40 94',
          phoneShort:'799384094',
          facebook:'https://www.facebook.com/ritualornaments',
          instagram:'https://www.instagram.com/ritualornaments/'
        },
     
        menu:[
        {
          title:"Home",
          location:"/"
        },
         {
          title:"Portfolio",
          location:"/portfolio"
        },
        {
          title:"About",
          location:"/about"
        },
        {
          title:"Contact",
          location:"/contact"
        }
      ],
      navigation:false,
      navigationOpen:false,
      transition:false,
      rocket:true,
      destination:"none"
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      'document.readyState'(newValue) {
      console.log('Watcher: '+newValue);
    }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
     async created() {
      await this.getPosts();

     },
    // beforeMount() {},
    // render(h) { return h(); },
     mounted() {
      console.log('Readystate '+document.readyState);
      
     },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      getPosts() {
        Vue.axios.get('https://backend.ritualornaments.ch/wp-json/wp/v2/media').then((response) => {
        for(let i = 0 ; i < response.data.length; i++) {
            //this.wpPosts.push( response.data[i] );
            this.wpPosts.push( response.data[i].guid.rendered );
          }
          console.log("Posts are Loaded");
          console.log(this.wpPosts[0].featured_media_src_url);


      })
      this.getPages();
    },
    getPages(){

      Vue.axios.get('https://backend.ritualornaments.ch/wp-json/wp/v2/pages').then((response) => {
        for(let i = 0 ; i < response.data.length; i++) {
            this.wpPages.push( response.data[i] );
          }
          console.log("Pages are Loaded");
         // console.log(this.wpPosts[0].featured_media_src_url);


      })


    },
       mainNavigation(routerLocation){
      this.navigationOpen = false;
      
      this.transition = true;
      this.destination = routerLocation;
      
    },
    inTransition(){
      
      this.navigation = false;
      this.$router.push(this.destination)
    },
     tuneNavigation(){
       this.navigation = !this.navigation;
    }
    },
   
  };
</script>







<style lang="scss">
@import "./scss/app";
</style>
