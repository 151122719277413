<template>
    <div>

           <!-- all props & events -->
   <!-- <vue-easy-lightbox
      escDisabled
      :moveDisabled = true
      :scrollDisabled = false
      :visible="visible"
      :imgs="imgs"
      :index="index"
      swipeTolerance="1"
      @hide="handleHide"
    ></vue-easy-lightbox> -->

          <CoolLightBox 
             :items="wpPosts" 
              :index="index"
               @close="index = null">
            </CoolLightBox>



          <div class="portfolioContainer">
            <div class="portfolioImgContainer"  v-for="(item, x) in wpPosts" :key='x' >
             <!--<img  @click="showSingle(x)" :src="require(`../assets/portfolio/${item}`)"> -->
              <img  @click="showSingle(x)" :src="item"> 
            </div>
          </div>
      </div>
</template>
<script>
//import { appendFile } from 'fs';
//import VueEasyLightbox from 'vue-easy-lightbox'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  export default {
    name: 'PortfolioView',
    components: {
      //VueEasyLightbox
      CoolLightBox, 
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      wpPosts:{required: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        portfolioWP:[],
        portfolio:[
          {
            src:'296725435_463340621984925_8693236454855603981_n.jpg'
          },
          {
            src:'297092169_361820999450159_4246861367060814163_n.jpg'
          },
          {
            src:'297178832_1713519192346381_3914929060021792516_n.jpg'
          },
          {
            src:'297276811_1054271302121378_2395883340024793907_n.jpg'
          },
          {
            src:'297989954_616621196484107_4971556564566367533_n.jpg'
          },
          {
            src:'298767266_102639215862897_5564262676865751290_n.jpg'
          },
          {
            src:'299310385_469182108090838_985384041823303022_n.jpg'
          },
           {
            src:'299889900_1217648045676847_65810507612435422_n.jpg'
          }
        ],
         portfolioNeu:[
          
           require('../assets/portfolio/296725435_463340621984925_8693236454855603981_n.jpg'),
          
         
            require('../assets/portfolio/297092169_361820999450159_4246861367060814163_n.jpg'),
         
            require('../assets/portfolio/297178832_1713519192346381_3914929060021792516_n.jpg'),
          
            require('../assets/portfolio/297276811_1054271302121378_2395883340024793907_n.jpg'),
         
            require('../assets/portfolio/297989954_616621196484107_4971556564566367533_n.jpg'),
         
            require('../assets/portfolio/298767266_102639215862897_5564262676865751290_n.jpg'),
          
            require('../assets/portfolio/299310385_469182108090838_985384041823303022_n.jpg'),
         require('../assets/portfolio/299889900_1217648045676847_65810507612435422_n.jpg'),
          
        ],

         imgs: [],  // Img Url , string or Array of string
      visible: false,
      index: null   // default: 0
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
     created() {
      this.wpPostsSRC();
     },
    // beforeMount() {},
    // render(h) { return h(); },
     mounted() {
      
     },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
       showSingle(orgIndex) {
        this.index = orgIndex;
        for (var i = 0; i < this.wpPosts.length; i++){
  
            //this.imgs.push({'src': require('../assets/portfolio/' + this.portfolio[i].src) });
           this.imgs.push({'src': this.wpPosts[i] });
      }

     
      this.show()
    },
      wpPostsSRC(){
       
        for (var i = 0; i < this.wpPosts.length; i++){
          this.portfolioWP.push(this.wpPosts[i].featured_media_src_url);
        }
        console.log('Portfolio für Lightbox: ' + this.portfolioWP);
        console.log('HALLOOOOOO');
      },

    handleHide() {
      this.visible = false
    },
    show() {
      this.visible = true
    },

    },
  };
</script>
<style lang="scss">
.vel-toolbar{
  visibility: hidden;
}
img.vel-img{
  box-shadow: none  !important;
}

.vel-modal {
  background: rgba(0,0,0,.9) !important;
}
</style>